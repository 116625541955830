import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// Import React Router
// https://reactrouter.com/
import {
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";

// Import components
import Gate from './views/gate/Gate';
import Landing from './views/landing/Landing';
import Quiz from './views/quiz/Quiz';
import Franchise from './views/franchise/Franchise';
import Store from './views/store/Store';
import RhodeIsland from './views/rhodeisland/RhodeIsland';
import Meditation from './views/meditation/meditation';
import Legal from './views/legal/Legal';
import { getAnalytics, logEvent } from 'firebase/analytics';
import PageNotFound from './views/error/PageNotFound';
import Loyalty from './views/loyalty/Loyalty';
import { StackAdapt } from './components/StackAdapt';


export default function App() {
  const analytics = getAnalytics();
  // Set initial state
  const [showGate, setShowGate] = useState(false);
  const history = useHistory();
  // Acquire current location
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    // Display age gate upon mount
    if (!pathname.includes('/rhodeisland')) {
      setShowGate(true);
    }
  }, []); // eslint-disable-line

  // Scroll to top when route updates
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Build handler for age gate "yes" button
  const handleAgeGateYes = () => {
    logEvent(analytics, 'age_gate_yes');
    setShowGate(false);
  }

  function openLoyaltySignUp() {
    logEvent(analytics, 'age_gate_yes_to_loyalty');
    window.location.href = "https://lab.alpineiq.com/join/c/1998/2405";
    setShowGate(false);
  }

  function openSpokesReferral() {
    logEvent(analytics, 'spokes_referral_01');
    history.push("/googleads?utm_source=Spokes&utm_medium=PPC&utm_campaign=Google");
    window.location.href = "https://maps.app.goo.gl/2YgQJeSyzQQU1GkTA";
    setShowGate(false);
  }

  return (
    <>
      <StackAdapt />
      <Switch>
        <Route exact path="/">
        <Gate
        visible={showGate}
        handleAgeGateYes={handleAgeGateYes}
        />
        <Landing />
        </Route>
        <Route path="/store">
        <Gate
        visible={showGate}
        handleAgeGateYes={handleAgeGateYes}
        />
          <Store />
        </Route>
        <Route path="/quiz">
        <Gate
        visible={showGate}
        handleAgeGateYes={handleAgeGateYes}
        />
          <Quiz />
        </Route>
        <Route path="/franchising">
        <Gate
        visible={showGate}
        handleAgeGateYes={handleAgeGateYes}
        />
          <Franchise />
        </Route>
        <Route path="/rhodeisland">
          <RhodeIsland />
        </Route>
        <Route path="/meditation">
          <Meditation/>
        </Route>
        <Route exact path="/hipaa">
        <Gate
        visible={showGate}
        handleAgeGateYes={handleAgeGateYes}
        />
          <Legal />
        </Route>
        <Route exact path="/terms-use">
        <Gate
        visible={showGate}
        handleAgeGateYes={handleAgeGateYes}
        />
          <Legal />
        </Route>
        <Route exact path="/privacy-policy">
        <Gate
        visible={showGate}
        handleAgeGateYes={handleAgeGateYes}
        />
          <Legal />
        </Route>
        <Route exact path="/accessibility">
        <Gate
        visible={showGate}
        handleAgeGateYes={handleAgeGateYes}
        />
          <Legal />
        </Route>
        <Route exact path="/legal-disclaimers">
        <Gate
        visible={showGate}
        handleAgeGateYes={handleAgeGateYes}
        />
          <Legal />
        </Route>
        <Route exact path="/refund-policy">
        <Gate
        visible={showGate}
        handleAgeGateYes={handleAgeGateYes}
        />
          <Legal />
        </Route>
        <Route exact path="/cannabis-safety-information">
        <Gate
        visible={showGate}
        handleAgeGateYes={handleAgeGateYes}
        />
          <Legal />
        </Route>
        <Route exact path="/loyaltysignup">
        <Gate
        visible={showGate}
        handleAgeGateYes={openLoyaltySignUp}
        />
        <Loyalty/>
        </Route>
        <Route exact path="/googleads">
        <Gate
        visible={showGate}
        handleAgeGateYes={openSpokesReferral}
        />
        </Route>
        <Route path="*" >
            <PageNotFound/>
          </Route>
      </Switch>
    </>
  );
}
