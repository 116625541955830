import { useEffect } from 'react';

export const StackAdapt = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `!function(s,a,e,v,n,t,z){if(s.saq)return;n=s.saq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!s._saq)s._saq=n;n.push=n;n.loaded=!0;n.version='1.0';n.queue=[];t=a.createElement(e);t.async=!0;t.src=v;z=a.getElementsByTagName(e)[0];z.parentNode.insertBefore(t,z)}(window,document,'script','https://tags.srv.stackadapt.com/events.js');saq('rt', 'rdxLgAPZuPWPPm5IMhcdC9');`;
    document.head.appendChild(script);

    const noscript = document.createElement('noscript');
    const img = document.createElement('img');
    img.src = 'https://tags.srv.stackadapt.com/rt?sid=rdxLgAPZuPWPPm5IMhcdC9';
    img.width = 1;
    img.height = 1;
    noscript.appendChild(img);
    document.head.appendChild(noscript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(noscript);
    };
  }, []);

  return null;
};
